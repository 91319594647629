import { PaymentsProfileRepository } from '../api'
import { PaymentsProfile } from './interfaces/payments.interfaces'

class PaymentsProfileService {
    async getPaymentsProfile({ cache } = { cache: true }): Promise<PaymentsProfile> {
        const {
            accountType,
            crmCustomerHasUnpaidInvoices,
            marketplacePaymentAccountActionRequired,
            fee,
            marketplacePaymentAccountCanOnboard,
            marketplacePaymentAccountConnectedCustomers,
            areIntegratedPaymentsTermsAccepted,
            marketplacePaymentAccountIsSalesFlow,
            areSalesFlowTermsAccepted
        } = await PaymentsProfileRepository.getPaymentsProfile({ cache })

        return {
            accountType,
            areIntegratedPaymentsTermsAccepted,
            canOnboard: marketplacePaymentAccountCanOnboard,
            hasUnpaidInvoices: !!crmCustomerHasUnpaidInvoices,
            isPaymentsActionRequired: !!marketplacePaymentAccountActionRequired,
            fee: {
                isAllowedToMigrate: !!fee.marketplacePaymentAccountIsAllowedToMigrate,
                migrationPaymentFee: fee.marketplacePaymentAccountMigrationPaymentFee,
                paymentFee: fee.marketplacePaymentAccountPaymentFee
            },
            customerIds: marketplacePaymentAccountConnectedCustomers ?? [],
            isSalesFlow: marketplacePaymentAccountIsSalesFlow,
            areSalesFlowTermsAccepted
        }
    }

    acceptSalesFlow(): Promise<void> {
        return PaymentsProfileRepository.acceptSalesFlow()
    }
}

export default new PaymentsProfileService()
