import { ChatUserType, PatientChatCategory, PatientChatFolder } from '@/features/chat/domain/enums/chat.enums'

import repository from '../api/patientsConversations.api'
import {
    GetConversationByChatIdOptions,
    GetConversationByChatIdResponse,
    PatientConversation
} from './interfaces/conversations.interfaces'

class PatientsConversationsService {
    getConversationByPatientId(patientId: number): Promise<PatientConversation> {
        return repository.getConversationByPatientId(patientId)
    }

    async getConversationByChatId(
        chatId: number,
        hostId: number,
        options: GetConversationByChatIdOptions = { fetchConversation: true, fetchPatient: true, useProviderApi: true }
    ): Promise<GetConversationByChatIdResponse> {
        const { isMigratedToChatApp } = await repository.fetchPatientChatAppMigration(chatId)

        if (!isMigratedToChatApp) {
            return this.legacyGetConversationByChatId(chatId, hostId, options)
        }

        return {
            patient: options.fetchPatient ? await repository.fetchChatPatientData(chatId, hostId) : null,
            conversation: options.fetchConversation ? await repository.fetchChatConversationData(chatId, hostId) : null
        }
    }

    prepareResponse(conversation: PatientConversation): GetConversationByChatIdResponse {
        const { patientBasicInfo, members, channelUrl, canBookOnline, lastMessageReadByMpWeb, tag } = conversation

        return {
            conversation: {
                tag,
                providerId: channelUrl,
                lastReadBrowserPageAt: lastMessageReadByMpWeb,
                members: members.map(({ name, id, patientProfileId, patientId }) => {
                    const isUser = !patientProfileId && !patientId
                    return {
                        displayName: name,
                        providerId: id,
                        avatarUrl: null,
                        type: isUser ? ChatUserType.Doctor : ChatUserType.MarketplaceUser // using default types to support new APIs
                    }
                })
            },
            patient: {
                patientId: patientBasicInfo.patientId,
                patientProfileId: patientBasicInfo.patientProfileId,
                deleted: patientBasicInfo.deleted,
                futureBookingId: `${patientBasicInfo.futureEventId}`,
                canBookOnline,
                allowedToManageVisit: patientBasicInfo.allowedToManageVisit || false,
                fullName: patientBasicInfo.name,
                email: patientBasicInfo.email,
                hasAllInfoRequiredForChat: !!(
                    patientBasicInfo.phone ||
                    patientBasicInfo.email ||
                    patientBasicInfo.alternatePhone
                )
            }
        }
    }

    async legacyGetConversationByChatId(
        chatId: number,
        hostId: number,
        options: GetConversationByChatIdOptions
    ): Promise<GetConversationByChatIdResponse> {
        if (hostId == null && options.useProviderApi && options.providerId) {
            try {
                const response = await repository.getConversationByProviderId(options.providerId)
                return this.prepareResponse(response)
            } catch {
                const response = await repository.getConversationByChatId(chatId, hostId)
                return this.prepareResponse(response)
            }
        }
        const response = await repository.getConversationByChatId(chatId, hostId)

        return this.prepareResponse(response)
    }

    async moveConversationToFolder(
        category: PatientChatCategory,
        patientId: number,
        providerId?: string,
        hostId?: number,
        resolvePatientRequests?: boolean
    ): Promise<any> {
        const { isMigratedToChatApp } = await repository.fetchPatientChatAppMigration(patientId)

        if (providerId && !hostId) {
            return repository.moveConversationToCategoryByProvider(category, providerId, resolvePatientRequests)
        }

        if (!isMigratedToChatApp) {
            const type = category === PatientChatCategory.Main ? PatientChatFolder.Inbox : PatientChatFolder.Done
            return this.legacyMoveConversationToCategory(type, patientId, hostId)
        }

        return repository.moveConversationToCategory(category, patientId, hostId)
    }

    async legacyMoveConversationToCategory(type: string, patientId: number, hostId?: number): Promise<any> {
        return repository.moveConversationToFolder(type, patientId, hostId)
    }

    async sendInvoicesToPatient(patientId: number, invoicesIds: number[]): Promise<void> {
        await repository.sendInvoicesToPatient(patientId, invoicesIds)
    }

    async sendQuotesToPatient(patientId: number, quotesIds: number[]): Promise<void> {
        await repository.sendQuotesToPatient(patientId, quotesIds)
    }

    async sendVouchersToPatient(patientId: number, voucherIds: number[]): Promise<void> {
        await repository.sendVouchersToPatient(patientId, voucherIds)
    }

    async resyncSendbird() {
        await repository.resyncSendbird()
    }

    async resyncSendbirdByChannelUrl(channelUrl: string) {
        await repository.resyncSendbirdByChannelUrl(channelUrl)
    }

    getIsPatientCallCenterOperator(patientId: number) {
        return repository.getIsPatientCallCenterOperator(patientId)
    }

    async getAllowPatientReplyToggleValue(patientId: number, hostId?: number) {
        const response = await repository.getAllowPatientReplyToggleValue(patientId, hostId)
        return response.canReplyToChat
    }

    async updateAllowPatientReplyToggleValue(patientId: number, value: boolean, hostId?: number) {
        return repository.updateAllowPatientReplyToggleValue(patientId, value, hostId)
    }
}

export default new PatientsConversationsService()
