import { SETTINGS_GENERAL } from '../../dependencies'

export const SETTINGS_INSURANCES_CONFIGURATION_ROUTES = {
    ADDRESSES: 'insurances-configuration-addresses',
    LIST: 'insurances-configuration-list',
    DETAILS: 'insurances-configuration-details',
    ADD: 'insurances-configuration-add'
}

export { SETTINGS_GENERAL }
