import { h } from 'vue'

import { middleware as featureToggleMiddleware } from '@/core/ui/plugins/featureToggle'
import { ROUTE_CALENDAR_BASE_NAME } from '@/features/calendar/ui/constants/routes.constants'

import {
    SETTINGS_BOOKING_QUESTIONS_ROUTE,
    SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
    SETTINGS_BOOKING_RULES_ROUTE
} from '../constants/router.constants'

const BookingRulesIndex = () => import(/* webpackChunkName: "booking-rules-index" */ '../views/BookingRulesIndex.vue')

const BookingRulesIndexWatson = () =>
    import(/* webpackChunkName: "booking-rules-index" */ '../views/BookingRulesIndexWatson.vue')

const BookingRulesTable = () =>
    import(/* webpackChunkName: "booking-rules-table" */ '../components/bookingRules/BookingRulesTable.vue')

const BookingNotes = () =>
    import(/* webpackChunkName: "booking-rules-notes" */ '../components/bookingRules/notes/BookingNotes.vue')
const BookingQuestions = () =>
    import(/* webpackChunkName: "booking-questions" */ '../components/bookingQuestions/BookingQuestions.vue')
const NoteSetEdition = () =>
    import(
        /* webpackChunkName: "booking-rules-note-set-edition" */ '../components/bookingRules/notes/NoteSetEdition.vue'
    )
const QuestionSetEdition = () =>
    import(
        /* webpackChunkName: "booking-rules-question-set-edition" */ '../components/bookingQuestions/QuestionSetEdition.vue'
    )

export default [
    {
        path: 'booking-rules',
        component: {
            render(this: any, c: any): ReturnType<typeof h> {
                return c(
                    this.$root.$featureToggle.ensure('BookingRulesWatson', true)
                        ? BookingRulesIndexWatson
                        : BookingRulesIndex
                )
            }
        },
        children: [
            {
                name: SETTINGS_BOOKING_RULES_ROUTE,
                path: 'notes',
                component: BookingNotes
            },
            {
                name: SETTINGS_BOOKING_QUESTIONS_ROUTE,
                path: 'questions',
                component: BookingQuestions
            }
        ]
    },
    {
        name: SETTINGS_BOOKING_RULES_NOTE_SET_EDITION_ROUTE,
        path: 'booking-rules/notes/note-set/:id?',
        component: NoteSetEdition
    },
    {
        path: 'booking-rules/questions/question-set/:id?',
        name: SETTINGS_BOOKING_RULES_QUESTION_SET_EDITION_ROUTE,
        component: QuestionSetEdition
    }
]
