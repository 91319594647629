import { SETTINGS_INSURANCES_CONFIGURATION_ROUTES } from './routes.constants'

const Index = () => import(/* webpackChunkName: "insurances-configuration-index" */ '../views/Index.vue')
const InsurancesAddresses = () =>
    import(/* webpackChunkName: "insurances-configuration-addresses" */ '../views/InsurancesAddresses.vue')
const InsurancesList = () =>
    import(/* webpackChunkName: "insurances-configuration-list" */ '../views/InsurancesList.vue')
const InsuranceDetails = () =>
    import(/* webpackChunkName: "insurances-configuration-details" */ '../views/InsuranceDetails.vue')

export default [
    {
        path: 'settings/insurances',
        component: Index,
        children: [
            {
                path: '',
                name: SETTINGS_INSURANCES_CONFIGURATION_ROUTES.ADDRESSES,
                component: InsurancesAddresses
            },
            {
                path: ':activeWorkplaceId(\\d+)/:activeFacilityId(\\d+)/list',
                name: SETTINGS_INSURANCES_CONFIGURATION_ROUTES.LIST,
                component: InsurancesList
            },
            {
                path: ':activeWorkplaceId(\\d+)/:activeFacilityId(\\d+)/:insuranceId(\\d+)',
                name: SETTINGS_INSURANCES_CONFIGURATION_ROUTES.DETAILS,
                component: InsuranceDetails
            },
            {
                path: ':activeWorkplaceId(\\d+)/:activeFacilityId(\\d+)/add',
                name: SETTINGS_INSURANCES_CONFIGURATION_ROUTES.ADD,
                component: InsuranceDetails
            }
        ]
    }
]
