import UserInfoData from '@/bootstrap/monitor/interfaces/userMetric.interface'
import cultureLibrariesMap from '@/config/cultureLibrariesMap.json'
import mapSettingsResponse from '@/core/api/utils/mapSettingResponse'
import { LATAM_COUNTRY_CODES } from '@/core/domain/constants/countryCode.constants'
import { GettersAdaptor } from '@/interfaces'
import { isArray } from '@/utils/array'
import { currency } from '@/utils/number-format'

import { AccessScopes } from '../../domain/enums/permissions.enums'
import { UserType } from '../../domain/enums/userType.enums'
import { ProfileEntity } from '../../domain/interfaces/profile.interfaces'
import SegmentationService from '../../domain/segmentation.service'
import { getSAASToken } from '../../domain/utils/token.utils'
import { AuthGetters, AuthGetterTypes } from './types'

const getters: GettersAdaptor<AuthGetters, any, any> = {
    [AuthGetterTypes.CanActivateCheckIn](state, otherGetters) {
        return (
            !otherGetters[AuthGetterTypes.HasPreCheckinEnabled] &&
            !otherGetters[AuthGetterTypes.IsMedicalCenter] &&
            !otherGetters[AuthGetterTypes.IsSecretary]
        )
    },
    [AuthGetterTypes.CanBlockPatients](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Settings)
    },
    [AuthGetterTypes.CanCreateDeviceSchedules](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings?.deviceSchedules === 'true'
    },
    [AuthGetterTypes.CanCreateInvoice](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToInvoices] && otherGetters[AuthGetterTypes.HasInvoicingEnabled]
    },
    [AuthGetterTypes.CanEditEhr](state, otherGetters, rootState, rootGetters) {
        const isEnabled = rootGetters.feature('displayehr')

        return isEnabled && otherGetters[AuthGetterTypes.IsDoctor]
    },
    [AuthGetterTypes.CanEnablePrescriptionSharing](state, otherGetters, rootState, rootGetters) {
        return (
            rootGetters.feature('autosendingprescriptionsviathechat') &&
            (otherGetters[AuthGetterTypes.IsDoctor] || otherGetters[AuthGetterTypes.IsImpersonator])
        )
    },
    [AuthGetterTypes.CanExportPatients](state, otherGetters) {
        return (
            !otherGetters[AuthGetterTypes.IsImpersonator] &&
            (otherGetters[AuthGetterTypes.IsDoctor] || otherGetters[AuthGetterTypes.IsRealClinic])
        )
    },
    [AuthGetterTypes.CanManageMedicalCenter](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser] &&
            (otherGetters[AuthGetterTypes.GetAuthUser].userType === UserType.MedicalCenter ||
                otherGetters[AuthGetterTypes.GetAuthUser].userType === UserType.SecretaryMedicalCenter)
        )
    },
    [AuthGetterTypes.CanUserCreateEhr](state, otherGetters) {
        return otherGetters[AuthGetterTypes.IsDoctor]
    },
    [AuthGetterTypes.CanViewEhr](state, otherGetters) {
        return otherGetters[AuthGetterTypes.CanEditEhr] || otherGetters[AuthGetterTypes.IsMedicalCenter]
    },
    [AuthGetterTypes.CheckAllowedCountries]: (state, otherGetters) => (countries: any) => {
        return isArray(countries) && countries.includes(otherGetters[AuthGetterTypes.GetCountryCode].toLowerCase())
    },
    [AuthGetterTypes.GetAccountUserById]: (state, otherGetters) => (id: any) => {
        return otherGetters[AuthGetterTypes.GetAccountUsers].find(
            ({ docplannerDoctorId }: any) => docplannerDoctorId === id
        )
    },
    [AuthGetterTypes.GetAccountUsers](state) {
        return state.accountUsers
    },
    [AuthGetterTypes.GetActivePhysicalSchedulesCount](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.activePhysicalSchedulesCount || 0
    },
    [AuthGetterTypes.GetActiveSchedules](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]
            ? otherGetters[AuthGetterTypes.GetAuthUser].activeSchedules
            : null
    },
    [AuthGetterTypes.GetActiveVirtualSchedulesCount](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.activeVirtualSchedulesCount || 0
    },
    [AuthGetterTypes.GetAuthStatus](state) {
        return state.status
    },
    [AuthGetterTypes.GetAuthUser](state) {
        return state.user
    },
    [AuthGetterTypes.UserTypeId](state) {
        return state.user.userTypeId
    },
    [AuthGetterTypes.MedicalCenterId](state) {
        return state.user.medicalCenterId
    },
    [AuthGetterTypes.GetClinicName](state, otherGetters) {
        const { clinicName = '' }: any = otherGetters[AuthGetterTypes.GetAuthUser] || {}

        return clinicName
    },
    [AuthGetterTypes.GetCountryCode](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]
            ? otherGetters[AuthGetterTypes.GetAuthUser].countryCode
            : undefined
    },
    [AuthGetterTypes.GetCurrency](state, otherGetters, rootState, rootGetters) {
        const cultureKey = rootGetters.culture as keyof typeof cultureLibrariesMap
        const culture = cultureLibrariesMap[cultureKey].numeraljs
        return currency(culture)
    },
    [AuthGetterTypes.GetDefaultAccountUser](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAccountUsers][0]
    },
    [AuthGetterTypes.GetDoctorName](state, otherGetters) {
        if (!otherGetters[AuthGetterTypes.GetAuthUser]) {
            return ''
        }
        return `${otherGetters[AuthGetterTypes.GetAuthUser].firstName} ${
            otherGetters[AuthGetterTypes.GetAuthUser].lastName
        }`
    },
    [AuthGetterTypes.GetHostName](state, otherGetters) {
        return (otherGetters[AuthGetterTypes.IsSecretary] && otherGetters[AuthGetterTypes.CanManageMedicalCenter]) ||
            otherGetters[AuthGetterTypes.IsSecretaryOfGruppo]
            ? otherGetters[AuthGetterTypes.GetClinicName]
            : otherGetters[AuthGetterTypes.GetUserNameWithTitle]
    },
    [AuthGetterTypes.GetSaasNavigation](state) {
        return state.saasNavigation
    },
    [AuthGetterTypes.GetUserAvatar]: state => userId => {
        const user = state.accountUsers.find(({ id }: any) => id === userId)
        return user ? user.photo.smallUrl : null
    },
    [AuthGetterTypes.GetUserId](state) {
        return state.user && state.user.id ? state.user.id : undefined
    },
    [AuthGetterTypes.GetHostId](state) {
        return state.user && state.user.hostId ? state.user.hostId : undefined
    },
    [AuthGetterTypes.GetParentEntityId](state, otherGetters) {
        if (!state.user) {
            return undefined
        }
        return (
            state.user.medicalCenterId ??
            otherGetters[AuthGetterTypes.GetHostId] ??
            otherGetters[AuthGetterTypes.GetUserId]
        )
    },
    [AuthGetterTypes.GetUserName](state) {
        if (!state.user) {
            return ''
        }

        return state.user.firstName + (state.user.lastName ? ` ${state.user.lastName}` : '')
    },
    [AuthGetterTypes.GetUserNameWithTitle](state, otherGetters) {
        const title = state.user && state.user.title ? `${state.user.title} ` : ''

        return title + otherGetters[AuthGetterTypes.GetUserName]
    },
    [AuthGetterTypes.HasAccessToAgendaView](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser] &&
            !otherGetters[AuthGetterTypes.CanManageMedicalCenter] &&
            !otherGetters[AuthGetterTypes.AllowBookingRequests]
        )
    },
    [AuthGetterTypes.HasAccessToCampaigns](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Campaigns)
    },
    [AuthGetterTypes.HasAccessToDashboard](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Dashboard)
    },
    [AuthGetterTypes.HasAccessToInvoices](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Invoices)
    },
    [AuthGetterTypes.HasAccessToMessages](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Messages)
    },
    [AuthGetterTypes.HasAccessToPatientFinancialTab](state, otherGetters) {
        const userAccesses = otherGetters[AuthGetterTypes.GetAuthUser].userAccesses || []

        if (userAccesses.length === 0 && !otherGetters[AuthGetterTypes.IsSecretary]) {
            return true
        }
        const { enabled } = userAccesses.find(({ accessId }: any) => accessId === AccessScopes.PatientFinancialTab) || {
            enabled: false
        }
        return enabled
    },
    [AuthGetterTypes.HasAccessToPatients](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Patients)
    },
    [AuthGetterTypes.HasAccessToReportDaily](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.ReportDaily)
    },
    [AuthGetterTypes.HasAccessToReportVisits](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Report)
    },
    [AuthGetterTypes.HasAccessToScope]: (state, otherGetters) => (scope: any) => {
        const { enabled = true } =
            (otherGetters[AuthGetterTypes.GetAuthUser] &&
                otherGetters[AuthGetterTypes.GetAuthUser].userAccesses.find(
                    ({ accessId }: any) => accessId === scope
                )) ||
            {}
        return enabled
    },
    [AuthGetterTypes.HasAccessToSettings](state, otherGetters) {
        return otherGetters[AuthGetterTypes.HasAccessToScope](AccessScopes.Settings)
    },
    [AuthGetterTypes.HasAccessToStartGuide](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser]?.hasStartGuide &&
            otherGetters[AuthGetterTypes.IsDoctorWithOwnSchedules]
        )
    },
    [AuthGetterTypes.HasAccessToUsersSection](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.IsDoctorWithOwnSchedules] ||
            otherGetters[AuthGetterTypes.IsDoctorWithScheduleInFakeClinic] ||
            otherGetters[AuthGetterTypes.IsMedicalCenter]
        )
    },
    [AuthGetterTypes.HasAdvanceAppointmentEnabled](state) {
        return state.user && state.user.advanceAppointmentEnabled
    },
    [AuthGetterTypes.HasCenterSchedules](state, otherGetters) {
        return (
            state.user &&
            otherGetters[AuthGetterTypes.IsDoctor] &&
            Object.prototype.hasOwnProperty.call(state.user, 'hasCenterSchedules') &&
            state.user.hasCenterSchedules
        )
    },
    [AuthGetterTypes.HasCommissionsEnabled](state) {
        return state.user && state.user.isIncomeSplitEnabled
    },
    [AuthGetterTypes.HasHideInvoiceSendConfirmation](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings &&
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings.hideInvoiceSendConfirmation === 'true'
        )
    },
    [AuthGetterTypes.HasPaymentNotification](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings?.paymentNotification === 'true'
    },
    [AuthGetterTypes.HasInvoicingEnabled](state) {
        return state.user && state.user.invoicingEnabled
    },
    [AuthGetterTypes.HasPatientGDPR](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser] &&
            otherGetters[AuthGetterTypes.GetAuthUser].settings &&
            otherGetters[AuthGetterTypes.GetAuthUser].settings.hasLopd
        )
    },
    [AuthGetterTypes.HasPreCheckinEnabled](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.checkIn === 'true'
    },
    [AuthGetterTypes.HasUnmaskedData](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.isUnmasked === 'true'
    },
    [AuthGetterTypes.HasUserAccessToPatientEditPage](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings
    },
    [AuthGetterTypes.HasUserAccessToPms](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings &&
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings.hasAccessToPms === 'true'
        )
    },
    [AuthGetterTypes.IsDashboardVisible](state, otherGetters, rootState, rootGetters) {
        return (
            (otherGetters[AuthGetterTypes.IsDoctor] && rootGetters.feature('displayDashboard')) ||
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings.showDashboard
        )
    },
    [AuthGetterTypes.HasDashboardVisible](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings?.showDashboard === 'true'
    },
    [AuthGetterTypes.IsDoctor](state) {
        return state.user && state.user.userType === UserType.Doctor
    },
    [AuthGetterTypes.IsDoctorInGruppo](state) {
        return state.user && state.user.isDoctorInGruppo
    },
    [AuthGetterTypes.WorksInGruppo](state, otherGetters) {
        return otherGetters[AuthGetterTypes.IsDoctorInGruppo] || otherGetters[AuthGetterTypes.IsSecretaryOfGruppo]
    },
    [AuthGetterTypes.IsDoctorOrSecretary](state, otherGetters) {
        return otherGetters[AuthGetterTypes.IsDoctor] || otherGetters[AuthGetterTypes.IsSecretary]
    },
    [AuthGetterTypes.IsDoctorWithClinicSchedules](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.IsDoctorWithScheduleInFakeClinic] ||
            otherGetters[AuthGetterTypes.HasCenterSchedules]
        )
    },
    [AuthGetterTypes.IsDoctorWithOwnSchedules](state, otherGetters) {
        return (
            state.user &&
            otherGetters[AuthGetterTypes.IsDoctor] &&
            Object.prototype.hasOwnProperty.call(state.user, 'hasPrivateSchedules') &&
            state.user.hasPrivateSchedules
        )
    },
    [AuthGetterTypes.IsDoctorWithScheduleInFakeClinic](state, otherGetters) {
        return (
            state.user &&
            otherGetters[AuthGetterTypes.IsDoctor] &&
            Object.prototype.hasOwnProperty.call(state.user, 'hasScheduleInFakeClinic') &&
            state.user.hasScheduleInFakeClinic
        )
    },
    [AuthGetterTypes.IsFakeClinic](state) {
        return state.user && state.user.isFakeClinic
    },
    [AuthGetterTypes.IsGeneralPractitioner](state) {
        return state.user && state.user.isGeneralPractitioner
    },
    [AuthGetterTypes.IsPublicPractitioner](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.isPublicPractitioner
    },
    [AuthGetterTypes.IsImpersonator](state) {
        return state.user && state.user.isImpersonator
    },
    [AuthGetterTypes.IsLoggedIn](state) {
        return !!getSAASToken() && !!state.user
    },
    [AuthGetterTypes.IsMandatoryReasonToDisableSuggestNextBookingOnline](state, otherGetters) {
        return (
            otherGetters[AuthGetterTypes.GetAuthUser].userSettings &&
            (otherGetters[AuthGetterTypes.GetAuthUser].userSettings.mandatoryReasonToDisableSuggestNextBookingOnline ===
                'true' ||
                otherGetters[AuthGetterTypes.GetAuthUser].userSettings
                    .mandatoryReasonToDisableSuggestNextBookingOnline === undefined)
        )
    },
    [AuthGetterTypes.IsMedicalCenter](state) {
        return state.user && state.user.userType === UserType.MedicalCenter
    },
    [AuthGetterTypes.IsMultiUser](state) {
        return state.accountUsers.length > 1
    },
    [AuthGetterTypes.IsRealClinic](state, otherGetters) {
        return otherGetters[AuthGetterTypes.IsMedicalCenter] && !otherGetters[AuthGetterTypes.IsFakeClinic]
    },
    [AuthGetterTypes.IsSaasOnly](state) {
        return state.saasOnly
    },
    [AuthGetterTypes.IsSecretary](state) {
        return (
            state.user &&
            (state.user.userType === UserType.Secretary ||
                state.user.userType === UserType.FakeSecretary ||
                state.user.userType === UserType.SecretaryMedicalCenter)
        )
    },
    [AuthGetterTypes.IsSecretaryOfIndividualDoctor](state) {
        return state.user && state.user.userType === UserType.Secretary
    },
    [AuthGetterTypes.IsSecretaryFakeClinic](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].isSecretaryFakeClinic
    },
    [AuthGetterTypes.IsSecretaryMedicalCenter](state) {
        return state.user.userType === UserType.SecretaryMedicalCenter
    },
    [AuthGetterTypes.IsSecretaryOfGruppo](state) {
        return state.user?.isSecretaryOfGruppo
    },
    [AuthGetterTypes.IsSecretaryOfPublicPractitioner](state) {
        return state.user?.isSecretaryOfPublicPractitioner
    },
    [AuthGetterTypes.IsShowingEventsCancelledByDoctor](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser].userSettings?.displayCancelledEventsByDoctor === 'true'
    },
    [AuthGetterTypes.ShowNameBeforeSurname](state, otherGetters, rootState, rootGetters) {
        return rootGetters.feature('patientnamebeforesurname') || rootGetters.countrySpecificShowNameBeforeSurname
    },
    [AuthGetterTypes.IsLatamCountry](state, otherGetters) {
        return LATAM_COUNTRY_CODES.includes(otherGetters[AuthGetterTypes.GetCountryCode])
    },
    [AuthGetterTypes.HasPrivacyPolicyEnabled](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.PrivacyPolicySetting === 'true'
    },
    [AuthGetterTypes.HasTicketBaiActivated](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.ticketbai === 'true'
    },
    [AuthGetterTypes.VoicemailSelfService](state, otherGetters, rootState, rootGetters) {
        return rootGetters.feature('voicemailselfservice')
    },
    [AuthGetterTypes.GetUserInfoForSentryTags](state, otherGetters): UserInfoData {
        // TODO: move to segmentation.service + reuse elsewhere - WSTB-359
        const isClinic = (user: ProfileEntity) =>
            user.userType === UserType.MedicalCenter || user.userType === UserType.SecretaryMedicalCenter

        return {
            clinicSize:
                // TODO: state.user?.schedulesCount was temporary added to support countries with late deploy (p2)
                state.user && isClinic(state.user) && state.user?.schedulesCount
                    ? SegmentationService.getClinicSizeSegment(state.user)
                    : null,
            countryCode: otherGetters[AuthGetterTypes.GetCountryCode],
            docplannerDoctorId: state.user?.docplannerDoctorId || null,
            docplannerUserId: state.user?.docplannerUserId || null,
            docplannerId: state.user?.id || null,
            userType: state.user?.userType || null
        }
    },
    [AuthGetterTypes.HasVideoCallMultipleParticipants](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.multipleParticipantsVideoCall === 'true'
    },
    [AuthGetterTypes.GetCommercialType](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.commercialType
    },
    [AuthGetterTypes.GetSelectedRequestedTypes](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.requestsFilters || '{}'
    },
    [AuthGetterTypes.GetRequestsOrderIsAscending](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.requestsOrderIsAscending || false
    },
    [AuthGetterTypes.IsGruppo](state) {
        return state.user?.isGruppo
    },
    [AuthGetterTypes.IsSubscribed](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.isSubscribed
    },
    [AuthGetterTypes.PremiumLinks](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.premiumLinks
    },
    [AuthGetterTypes.IsWelcomeMessageEnabled](state, otherGetters) {
        return mapSettingsResponse(!otherGetters[AuthGetterTypes.GetAuthUser]?.hasCenterSchedules) as boolean
    },
    [AuthGetterTypes.AllowBookingRequests](state, otherGetters, rootState, rootGetters) {
        return (
            mapSettingsResponse(otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.allowBookingRequests) &&
            rootGetters.showBookingRequestsFeature
        )
    },
    [AuthGetterTypes.HasUserAccessToGpsFeatures](state, otherGetters, rootState, rootGetters) {
        return rootGetters.showGpFeatures && otherGetters[AuthGetterTypes.IsPublicPractitioner]
    },
    [AuthGetterTypes.HasBlockDistractors](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.blockDistractors === 'true'
    },
    [AuthGetterTypes.HasVideoConsultationTermsAccepted](state, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.videoConsultationTermsAccepted
    },
    [AuthGetterTypes.AppointmentPatientOwnership](_, otherGetters) {
        return otherGetters[AuthGetterTypes.GetAuthUser]?.userSettings?.appointmentPatientOwnership === 'true'
    }
}

export default getters
