import { CommercialTypeEnums } from '@/core/ui/enums/commercialType.enums'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { usePackaging } from '@/features/packaging/composables/usePackaging'
import { REVERSE_TRIAL } from '@/features/reverseTrial/constants/reverse-trial'
import { Consent } from '@/features/reverseTrial/interfaces/interfaces'

export const hasUserAccessToReverseTrial = () => {
    const { canUsePackaging, hasPrivateAddressesWithPlan } = usePackaging()

    return (
        ensureToggleIsEnabled('CanRegisterToReverseTrialStarterToPlus', true) &&
        canUsePackaging() &&
        hasPrivateAddressesWithPlan(CommercialTypeEnums.Starter)
    )
}

export const isReverseTrialPresent = (data: Array<Consent>) => {
    if (Array.isArray(data)) {
        return data.some(item => item.consentType === REVERSE_TRIAL)
    }
    return false
}
