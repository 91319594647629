import { datadogRum } from '@datadog/browser-rum'
import { navigation } from '@docplanner/one-tracking'
import Vue from 'vue'
import Router from 'vue-router'

import { ROUTER_NAVIGATION, ROUTER_NAVIGATION_LOGOUT } from '@/core/ui/constants/event.bus.constants'
import EventBus from '@/core/ui/libs/eventBus'
import { DateUnit, timeDifferent } from '@/core/ui/utils'
import { setCookie } from '@/core/ui/utils/cookies.utils'
import admin from '@/features/admin/ui/router/admin.router'
import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import auth from '@/features/auth/ui/router/auth.middleware'
import noAuth from '@/features/auth/ui/router/noAuth.middleware'
import { AuthActionTypes } from '@/features/auth/ui/store/types'
import calendar from '@/features/calendar/ui/router/calendar.router'
import campaigns from '@/features/campaigns/ui/router/campaigns.router'
import chat from '@/features/chat/ui/router/chat.router'
import dashboard from '@/features/dashboard/ui/router/dashboard.router'
import doctorInvitation from '@/features/doctorInvitation/ui/router/doctorInvitation.router'
import dpPlugins from '@/features/dpPlugins/ui/router/plugins.router'
import { formTemplateRoute } from '@/features/formTemplate/router/formBlueprints.router'
import holidays from '@/features/holidays/ui/router/holidays.router'
import invoice from '@/features/invoice/ui/router/invoice.router'
import landings from '@/features/landings/ui/router/landings.router'
import loginApp from '@/features/loginApp/ui/router/loginApp.router'
import migration from '@/features/migration/ui/router/migration.router'
import packageFeatures from '@/features/packaging/ui/router/packageFeatures.middleware'
import patient from '@/features/patient/ui/router/patient.router'
import payments from '@/features/payments/ui/router/payments.router'
import pms from '@/features/pms/ui/router/pms.router'
import pricingTerms from '@/features/pricingTerms/ui/router/pricingTerms.router'
import quotes from '@/features/quotes/ui/router/quotes.router'
import reports from '@/features/reports/ui/router/reports.router'
import settings from '@/features/settings/ui/router/settings.router'
import { SETUP_GUIDE_ROUTE } from '@/features/setupGuide'
import {
    doctorVideoConsultation,
    patientVideoConsultation,
    videoConsultationTerms
} from '@/features/videoConsultation/ui/router/videoConsultation.router'
import videoTutorials from '@/features/videoTutorials/ui/router/videoTutorials.router'
import voucher from '@/features/voucher/ui/router/voucher.router'
import waitingList from '@/features/waitingList/ui/router/waitingList.router'
import appReady from '@/middleware/appReady'
import countrySettings from '@/middleware/countrySettings'
import httpHeaders from '@/middleware/httpHeaders'
import i18n from '@/middleware/i18n'
import invitation from '@/middleware/invitation'
import mobile from '@/middleware/mobile'
import monitoring from '@/middleware/monitoring'
import onboardingRedirect from '@/middleware/onboardingRedirect'
import permissions from '@/middleware/permissions'
import redirection from '@/middleware/redirection'
import settingsWizard from '@/router/settingsWizard'
import bodyClass from '@/router/utils/bodyClass'
import headerTitle from '@/router/utils/headerTitle'
import { getApiLogoutPath, getRedirectURL, getViewNameForDataDog } from '@/router/utils/helpers'
import middlewares from '@/router/utils/middleware'
import store from '@/store'
import { getDecodedUrl } from '@/utils/url'

const AuthSelectUser = () => import(/* webpackChunkName: "select-user" */ '@/features/auth/ui/views/AuthSelectUser.vue')

const Home = () => import(/* webpackChunkName: "home" */ '@/core/ui/views/Home.vue')
const EmptyPlaceholder = () => import(/* webpackChunkName: "home" */ '@/core/ui/views/EmptyPlaceholder.vue')
const Error = () => import(/* webpackChunkName: "error" */ '@/core/ui/views/Error.vue')
const Unauthorized = () => import(/* webpackChunkName: "unauthorized" */ '@/features/auth/ui/views/Unauthorized.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/core/ui/views/NotFound.vue')
const Blank = () => import(/* webpackChunkName: "blank" */ '@/core/ui/views/Blank.vue')
const Private = () => import(/* webpackChunkName: "private" */ '@/features/auth/ui/views/Private.vue')
const Register = () => import(/* webpackChunkName: "register" */ '@/features/auth/ui/views/Register.vue')
const Mobile = () => import(/* webpackChunkName: "mobile" */ '@/core/ui/views/Mobile.vue')

Vue.use(Router)

const shouldAddToCookie = str => {
    if (str.length === 1 && str.indexOf('/') === 0) {
        return false
    }

    return str.indexOf('unauthorized') <= -1
}

const router = new Router({
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/error',
            name: 'error',
            component: Error,
            meta: {
                title: 'error',
                middleware: [i18n, appReady]
            },
            beforeEnter: (to, from, next) => {
                if (to.params?.error?.status === 401) {
                    next({
                        name: 'logout'
                    })
                } else {
                    next()
                }
            },
            props: route => {
                return {
                    error: route.params.error
                }
            }
        },
        {
            path: '/',
            component: Home,
            meta: {
                middleware: [redirection, i18n, countrySettings]
            },
            children: [
                patientVideoConsultation,
                videoConsultationTerms,
                {
                    path: 'register',
                    name: 'register',
                    component: Register,
                    meta: {
                        title: 'registro',
                        middleware: [noAuth, invitation, appReady]
                    }
                },
                {
                    path: '404',
                    name: 'not-found',
                    component: NotFound,
                    meta: {
                        title: 'an-error-has-occurred-not-found',
                        middleware: [appReady]
                    }
                },
                {
                    path: 'unauthorized',
                    name: 'unauthorized',
                    component: Unauthorized
                },
                {
                    path: 'logout',
                    name: 'logout',
                    component: Blank,
                    meta: {
                        title: 'logout',
                        middleware: appReady
                    },
                    beforeEnter: (to, from, next) => {
                        store.dispatch(AuthActionTypes.AuthLogout)
                        const redirect = getDecodedUrl(getRedirectURL(to))
                        window.location.href = redirect || getApiLogoutPath(to)
                    }
                },
                {
                    path: 'unknown',
                    name: 'unknown',
                    component: Blank,
                    meta: {
                        middleware: appReady
                    }
                },
                {
                    path: 'mobile',
                    name: 'mobile',
                    component: Mobile,
                    meta: {
                        middleware: [appReady, packageFeatures, auth]
                    }
                },
                {
                    path: '',
                    component: EmptyPlaceholder,
                    meta: {
                        middleware: [
                            auth,
                            monitoring,
                            packageFeatures,
                            permissions,
                            mobile,
                            i18n,
                            countrySettings,
                            httpHeaders,
                            appReady
                        ]
                    },
                    children: [
                        {
                            path: ROUTE_SELECT_USER,
                            name: ROUTE_SELECT_USER,
                            component: AuthSelectUser,
                            meta: {
                                title: ROUTE_SELECT_USER
                            }
                        },
                        {
                            path: '',
                            name: 'private-area',
                            component: Private,
                            children: [
                                {
                                    path: 'onboarding',
                                    name: 'onboarding',
                                    component: Blank,
                                    meta: {
                                        middleware: onboardingRedirect
                                    }
                                },
                                ...migration,
                                ...calendar,
                                reports,
                                dashboard,
                                loginApp,
                                settings,
                                formTemplateRoute,
                                payments,
                                campaigns,
                                videoTutorials,
                                ...settingsWizard,
                                ...admin,
                                patient,
                                ...dpPlugins,
                                ...landings,
                                chat,
                                pms,
                                voucher,
                                quotes,
                                doctorVideoConsultation,
                                invoice,
                                doctorInvitation,
                                holidays,
                                pricingTerms,
                                waitingList,
                                SETUP_GUIDE_ROUTE,
                                {
                                    path: '*',
                                    name: 'unknown-private',
                                    component: Blank,
                                    beforeEnter: (to, from, next) => {
                                        next({
                                            name: 'not-found'
                                        })
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    path: '*',
                    name: 'fallback-path',
                    component: Blank,
                    meta: {
                        middleware: appReady
                    }
                }
            ]
        }
    ]
})

let initialTime = null

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.middleware)) {
        middlewares({ to, from, next, router, store })

        const duration = timeDifferent(initialTime, new Date(), DateUnit.Second)
        if (duration !== 0) {
            const eventProperties = {
                properties: {
                    duration,
                    view: from.name,
                    from: from.name,
                    to: to.name
                }
            }
            Vue.prototype.$tracking.trackWithProperties(navigation.timeTracking, eventProperties)
        }
        initialTime = new Date()
        return
    }
    next()
})

router.afterEach((to, from) => {
    headerTitle(to, router)
    bodyClass(to, router)

    datadogRum.startView({
        name: getViewNameForDataDog(to)
    })

    window.productFruits?.pageChanged?.()

    Vue.prototype.$tracking.trackPage(to.path)
})

router.onReady(
    function () {
        EventBus.on(ROUTER_NAVIGATION, ({ name, query, params }) => {
            router.push(
                {
                    name,
                    query,
                    params
                },
                () => {},
                () => {}
            )
        })

        EventBus.on(ROUTER_NAVIGATION_LOGOUT, () => {
            if (shouldAddToCookie(router.currentRoute.path)) {
                setCookie('redirectUrl', router.currentRoute.path)
            }

            router.push({
                name: 'logout',
                query: {
                    redirectUrl: router.currentRoute.path
                }
            })
        })
    },
    error => {
        error.message = `VueRouter index: ${error.message}`
        throw error
    }
)

router.onError(error => {
    error.message = `VueRouter navigation error: ${error.message}`
    throw error
})

export default router
