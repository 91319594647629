export enum AuthGetterTypes {
    AllowBookingRequests = 'allowBookingRequests',
    AppointmentPatientOwnership = 'appointmentPatientOwnership',
    CanActivateCheckIn = 'canActivateCheckIn',
    CanBlockPatients = 'canBlockPatients',
    CanCreateDeviceSchedules = 'canCreateDeviceSchedules',
    CanCreateInvoice = 'canCreateInvoice',
    CanEditEhr = 'canEditEhr',
    CanEnablePrescriptionSharing = 'canEnablePrescriptionSharing',
    CanExportPatients = 'canExportPatients',
    CanManageMedicalCenter = 'managesMedicalCenter',
    CanUserCreateEhr = 'canUserCreateEhr',
    CanViewEhr = 'canViewEhr',
    CheckAllowedCountries = 'checkAllowedCountries',
    GetAccountUserById = 'accountUserById',
    GetAccountUsers = 'accountUsers',
    GetActivePhysicalSchedulesCount = 'activePhysicalSchedulesCount',
    GetActiveSchedules = 'activeSchedules',
    GetActiveVirtualSchedulesCount = 'activeVirtualSchedulesCount',
    GetAuthStatus = 'authStatus',
    GetAuthUser = 'authUser',
    GetClinicName = 'clinicName',
    GetCommercialType = 'commercialType',
    GetCountryCode = 'countryCode',
    GetCurrency = 'currency',
    GetDefaultAccountUser = 'defaultAccountUser',
    GetDoctorName = 'doctorName',
    GetHostId = 'hostId',
    GetHostName = 'hostName',
    GetParentEntityId = 'parentEntityId',
    GetRequestsOrderIsAscending = 'requestsOrderIsAscending',
    GetSaasNavigation = 'saasNavigation',
    GetSelectedRequestedTypes = 'selectedRequestTypes',
    GetUserAvatar = 'userAvatar',
    GetUserId = 'userId',
    GetUserInfoForSentryTags = 'userInfoForSentryTags',
    GetUserName = 'userName',
    GetUserNameWithTitle = 'userNameWithTitle',
    HasAccessToAgendaView = 'hasAccessToAgendaView',
    HasAccessToCampaigns = 'hasAccessToCampaigns',
    HasAccessToDashboard = 'hasAccessToDashboard',
    HasAccessToInvoices = 'hasAccessToInvoices',
    HasAccessToMessages = 'hasAccessToMessages',
    HasAccessToPatientFinancialTab = 'hasAccessToPatientFinancialTab',
    HasAccessToPatients = 'hasAccessToPatients',
    HasAccessToReportDaily = 'hasAccessToReportDaily',
    HasAccessToReportVisits = 'hasAccessToReportVisits',
    HasAccessToScope = 'hasAccessToScope',
    HasAccessToSettings = 'hasAccessToSettings',
    HasAccessToStartGuide = 'hasAccessToStartGuide',
    HasAccessToUsersSection = 'hasAccessToUsersSection',
    HasAdvanceAppointmentEnabled = 'hasAdvanceAppointmentEnabled',
    HasBlockDistractors = 'hasBlockDistractors',
    HasCenterSchedules = 'hasCenterSchedules',
    HasCommissionsEnabled = 'hasCommissionsEnabled',
    HasDashboardVisible = 'hasDashboardVisible',
    HasHideInvoiceSendConfirmation = 'hasHideInvoiceSendConfirmation',
    HasInvoicingEnabled = 'hasInvoicingEnabled',
    HasPatientGDPR = 'hasPatientGDPR',
    HasPaymentNotification = 'hasPaymentNotification',
    HasPreCheckinEnabled = 'hasPreCheckinEnabled',
    HasPrivacyPolicyEnabled = 'hasPrivacyPolicyEnabled',
    HasTicketBaiActivated = 'hasTicketBaiActivated',
    HasUnmaskedData = 'hasUnmaskedData',
    HasUserAccessToGpsFeatures = 'hasUserAccessToGpsFeatures',
    HasUserAccessToPatientEditPage = 'hasUserAccessToPatientEditPage',
    HasUserAccessToPms = 'hasUserAccessToPms',
    HasVideoCallMultipleParticipants = 'hasVideoCallMultipleParticipants',
    HasVideoConsultationTermsAccepted = 'hasVideoConsultationTermsAccepted',
    IsDashboardVisible = 'isDashboardVisible',
    IsDoctor = 'isDoctor',
    IsDoctorInGruppo = 'isDoctorInGruppo',
    IsDoctorOrSecretary = 'isDoctorOrSecretary',
    IsDoctorWithClinicSchedules = 'isDoctorWithClinicSchedules',
    IsDoctorWithOwnSchedules = 'isDoctorWithOwnSchedules',
    IsDoctorWithScheduleInFakeClinic = 'isDoctorWithScheduleInFakeClinic',
    IsFakeClinic = 'isFakeClinic',
    IsGeneralPractitioner = 'isGeneralPractitioner',
    IsGruppo = 'isGruppo',
    IsImpersonator = 'isImpersonator',
    IsLatamCountry = 'isLatamCountry',
    IsLoggedIn = 'isLoggedIn',
    IsMandatoryReasonToDisableSuggestNextBookingOnline = 'isMandatoryReasonToDisableSuggestNextBookingOnline',
    IsMedicalCenter = 'isMedicalCenter',
    IsMultiUser = 'isMultiUser',
    IsPublicPractitioner = 'isPublicPractitioner',
    IsRealClinic = 'isRealClinic',
    IsSaasOnly = 'saasOnly',
    IsSecretary = 'isSecretary',
    IsSecretaryFakeClinic = 'isSecretaryFakeClinic',
    IsSecretaryMedicalCenter = 'isSecretaryMedicalCenter',
    IsSecretaryOfGruppo = 'isSecretaryOfGruppo',
    IsSecretaryOfIndividualDoctor = 'isSecretaryOfIndividualDoctor',
    IsSecretaryOfPublicPractitioner = 'isSecretaryOfPublicPractitioner',
    IsShowingEventsCancelledByDoctor = 'isShowingEventsCancelledByDoctor',
    IsSubscribed = 'isSubscribed',
    IsWelcomeMessageEnabled = 'isWelcomeMessageEnabled',
    MedicalCenterId = 'medicalCenterId',
    PremiumLinks = 'premiumLinks',
    ShowNameBeforeSurname = 'showNameBeforeSurname',
    UserTypeId = 'userTypeId',
    VoicemailSelfService = 'voicemailSelfService',
    WorksInGruppo = 'worksInGruppo'
}

export type AuthGetters = {
    [AuthGetterTypes.CanActivateCheckIn]: boolean
    [AuthGetterTypes.CanBlockPatients]: boolean
    [AuthGetterTypes.CanCreateDeviceSchedules]: boolean
    [AuthGetterTypes.CanCreateInvoice]: boolean
    [AuthGetterTypes.CanEditEhr]: boolean
    [AuthGetterTypes.CanEnablePrescriptionSharing]: boolean
    [AuthGetterTypes.CanExportPatients]: boolean
    [AuthGetterTypes.CanManageMedicalCenter]: boolean
    [AuthGetterTypes.CanUserCreateEhr]: boolean
    [AuthGetterTypes.CanViewEhr]: boolean
    [AuthGetterTypes.CheckAllowedCountries]: (countries: any) => boolean
    [AuthGetterTypes.GetCommercialType]: string
    [AuthGetterTypes.GetAccountUserById]: (id: any) => any
    [AuthGetterTypes.GetAccountUsers]: any
    [AuthGetterTypes.GetActivePhysicalSchedulesCount]: any
    [AuthGetterTypes.GetActiveSchedules]: any
    [AuthGetterTypes.GetActiveVirtualSchedulesCount]: number
    [AuthGetterTypes.GetAuthStatus]: string
    [AuthGetterTypes.GetAuthUser]: any
    [AuthGetterTypes.GetClinicName]: string
    [AuthGetterTypes.GetCountryCode]: any
    [AuthGetterTypes.GetCurrency]: any
    [AuthGetterTypes.GetDefaultAccountUser]: any
    [AuthGetterTypes.GetDoctorName]: string
    [AuthGetterTypes.GetHostId]: number | undefined
    [AuthGetterTypes.GetHostName]: string
    [AuthGetterTypes.GetParentEntityId]: number | undefined
    [AuthGetterTypes.GetRequestsOrderIsAscending]: boolean | undefined
    [AuthGetterTypes.GetSaasNavigation]: any
    [AuthGetterTypes.GetSelectedRequestedTypes]: number[]
    [AuthGetterTypes.GetUserAvatar]: (userId: any) => string
    [AuthGetterTypes.GetUserId]: any
    [AuthGetterTypes.GetUserInfoForSentryTags]: any
    [AuthGetterTypes.GetUserName]: string
    [AuthGetterTypes.GetUserNameWithTitle]: string
    [AuthGetterTypes.HasAccessToAgendaView]: boolean
    [AuthGetterTypes.HasAccessToCampaigns]: boolean
    [AuthGetterTypes.HasAccessToDashboard]: boolean
    [AuthGetterTypes.HasAccessToInvoices]: boolean
    [AuthGetterTypes.HasAccessToMessages]: boolean
    [AuthGetterTypes.HasAccessToPatientFinancialTab]: boolean
    [AuthGetterTypes.HasAccessToPatients]: boolean
    [AuthGetterTypes.HasAccessToReportDaily]: boolean
    [AuthGetterTypes.HasAccessToReportVisits]: boolean
    [AuthGetterTypes.HasAccessToScope]: (scope: any) => boolean
    [AuthGetterTypes.HasAccessToSettings]: boolean
    [AuthGetterTypes.HasAccessToStartGuide]: boolean
    [AuthGetterTypes.HasAccessToUsersSection]: boolean
    [AuthGetterTypes.HasAdvanceAppointmentEnabled]: boolean
    [AuthGetterTypes.HasCenterSchedules]: boolean
    [AuthGetterTypes.HasCommissionsEnabled]: boolean
    [AuthGetterTypes.HasBlockDistractors]: boolean
    [AuthGetterTypes.HasHideInvoiceSendConfirmation]: boolean
    [AuthGetterTypes.HasInvoicingEnabled]: boolean
    [AuthGetterTypes.HasPatientGDPR]: boolean
    [AuthGetterTypes.HasPreCheckinEnabled]: boolean
    [AuthGetterTypes.HasPrivacyPolicyEnabled]: boolean
    [AuthGetterTypes.HasUnmaskedData]: boolean
    [AuthGetterTypes.HasUserAccessToPatientEditPage]: boolean
    [AuthGetterTypes.HasUserAccessToPms]: boolean
    [AuthGetterTypes.HasUserAccessToGpsFeatures]: boolean
    [AuthGetterTypes.HasVideoConsultationTermsAccepted]: boolean
    [AuthGetterTypes.HasPaymentNotification]: boolean
    [AuthGetterTypes.IsDashboardVisible]: boolean
    [AuthGetterTypes.IsDoctor]: boolean
    [AuthGetterTypes.IsDoctorInGruppo]: boolean
    [AuthGetterTypes.IsDoctorOrSecretary]: boolean
    [AuthGetterTypes.IsDoctorWithClinicSchedules]: boolean
    [AuthGetterTypes.IsDoctorWithOwnSchedules]: boolean
    [AuthGetterTypes.IsDoctorWithScheduleInFakeClinic]: boolean
    [AuthGetterTypes.IsFakeClinic]: boolean
    [AuthGetterTypes.IsGeneralPractitioner]: boolean
    [AuthGetterTypes.IsGruppo]: boolean
    [AuthGetterTypes.IsImpersonator]: boolean
    [AuthGetterTypes.IsLatamCountry]: boolean
    [AuthGetterTypes.IsLoggedIn]: boolean
    [AuthGetterTypes.IsMandatoryReasonToDisableSuggestNextBookingOnline]: boolean
    [AuthGetterTypes.IsMedicalCenter]: boolean
    [AuthGetterTypes.IsMultiUser]: boolean
    [AuthGetterTypes.IsPublicPractitioner]: boolean
    [AuthGetterTypes.IsRealClinic]: boolean
    [AuthGetterTypes.IsSaasOnly]: boolean
    [AuthGetterTypes.IsSecretary]: boolean
    [AuthGetterTypes.IsSecretaryFakeClinic]: boolean
    [AuthGetterTypes.IsSecretaryMedicalCenter]: boolean
    [AuthGetterTypes.IsSecretaryOfGruppo]: boolean
    [AuthGetterTypes.IsSecretaryOfIndividualDoctor]: boolean
    [AuthGetterTypes.IsSecretaryOfPublicPractitioner]: boolean
    [AuthGetterTypes.IsShowingEventsCancelledByDoctor]: boolean
    [AuthGetterTypes.IsSubscribed]: boolean
    [AuthGetterTypes.ShowNameBeforeSurname]: boolean
    [AuthGetterTypes.HasTicketBaiActivated]: boolean
    [AuthGetterTypes.VoicemailSelfService]: boolean
    [AuthGetterTypes.HasVideoCallMultipleParticipants]: boolean
    [AuthGetterTypes.HasDashboardVisible]: boolean
    [AuthGetterTypes.UserTypeId]: string
    [AuthGetterTypes.MedicalCenterId]: number | null
    [AuthGetterTypes.PremiumLinks]: object[] | null
    [AuthGetterTypes.WorksInGruppo]: boolean
    [AuthGetterTypes.AllowBookingRequests]: boolean
    [AuthGetterTypes.IsWelcomeMessageEnabled]: boolean
    [AuthGetterTypes.AppointmentPatientOwnership]: boolean
}
