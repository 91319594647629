import {
    WAITING_LIST_ROUTE,
    WAITING_LIST_ROUTE_NAME,
    WAITING_LIST_ROUTE_ONBOARDING,
    WAITING_LIST_ROUTE_ONBOARDING_NAME
} from '../constants/routes.constants'
import waitingListMiddleware from '../middlewares/waitingList.middleware'

const WaitingListIndex = () => import(/* webpackChunkName: "waiting-list-index" */ '../views/WaitingListIndex.vue')
const WaitingList = () => import(/* webpackChunkName: "waiting-list" */ '../views/WaitingList.vue')
const WaitingListOnboarding = () =>
    import(/* webpackChunkName: "waiting-list-onboarding" */ '../views/WaitingListOnboarding.vue')

export default {
    path: WAITING_LIST_ROUTE,
    component: WaitingListIndex,
    children: [
        {
            path: WAITING_LIST_ROUTE_ONBOARDING,
            name: WAITING_LIST_ROUTE_ONBOARDING_NAME,
            component: WaitingListOnboarding,
            meta: {
                title: 'advance-appointment-list-table-title'
            }
        },
        {
            path: '',
            name: WAITING_LIST_ROUTE_NAME,
            component: WaitingList,
            meta: {
                middleware: [waitingListMiddleware],
                title: 'advance-appointment-list-table-title'
            }
        }
    ]
}
