import { ADMIN_ROUTES } from '@/features/admin/ui/constants/routes.constants'
import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import { AuthGetterTypes } from '@/features/auth/ui/store/types'
import { SETTINGS_BOOKING_RULES_ROUTE } from '@/features/bookingRules/ui/constants/router.constants'
import { ROUTE_CALENDAR_AGENDA } from '@/features/calendar/ui/constants/routes.constants'
import { INVOICE_TUTORIAL_ROUTE, INVOICES_ROUTE } from '@/features/invoice/ui/constants/routes.constants'
import quotesPermissions from '@/features/quotes/ui/middleware/quotesPermissions.middleware'
import { ROUTE_ROOM_NAME, ROUTE_ROOMS_NAME } from '@/features/rooms/ui/constants/routes.constants'
import { DocumentationTemplatesRouteNames } from '@/features/settings/ui/enums/documentation.templates.enums'
import { SETUP_GUIDE_ROUTE } from '@/features/setupGuide/index.config'

export const pathPermissions = [
    quotesPermissions,
    {
        names: ['private-area'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.GetUserId],
        redirectTo: ROUTE_SELECT_USER
    },
    {
        names: ['settings-users'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToUsersSection],
        redirectTo: '/'
    },
    {
        names: ['landing-eprescription'],
        shouldRedirect: store => store.state.culture !== 'pl-PL',
        redirectTo: '/'
    },
    {
        names: [INVOICE_TUTORIAL_ROUTE],
        shouldRedirect: store => store.state.culture !== 'it-IT',
        redirectTo: '/'
    },
    {
        names: [ADMIN_ROUTES.ADMIN],
        shouldRedirect: store => !store.getters.hasAccessToAdminPanel,
        redirectTo: '/'
    },
    {
        names: ['chat.patients-conversation-list'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToMessages],
        redirectTo: '/'
    },
    {
        names: ['patients'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToPatients],
        redirectTo: '/'
    },
    {
        names: [INVOICES_ROUTE],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToInvoices],
        redirectTo: '/'
    },
    {
        names: ['reports-visits'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToReportVisits],
        redirectTo: '/'
    },
    {
        names: ['reports-cash-register'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToReportDaily],
        redirectTo: '/'
    },
    {
        names: ['dashboard'],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToDashboard],
        redirectTo: '/'
    },
    {
        names: [
            'settings-exportdata',
            'settings-general',
            'settings-notifications',
            'settings-rooms',
            'settings-room',
            'settings-room-new',
            'settings-schedule',
            'settings-schedules',
            'settings-services',
            'settings-service',
            'settings-service-schedule',
            'settings-service-new',
            'settings-users',
            'settings-user',
            DocumentationTemplatesRouteNames.Index,
            DocumentationTemplatesRouteNames.List,
            DocumentationTemplatesRouteNames.View,
            DocumentationTemplatesRouteNames.New,
            DocumentationTemplatesRouteNames.Edit,
            SETTINGS_BOOKING_RULES_ROUTE
        ],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToSettings],
        redirectTo: '/'
    },
    {
        names: [ROUTE_CALENDAR_AGENDA],
        shouldRedirect: store => !store.getters[AuthGetterTypes.HasAccessToAgendaView],
        redirectTo: '/'
    },
    {
        names: [ROUTE_ROOMS_NAME, ROUTE_ROOM_NAME],
        shouldRedirect: store => !store.getters[AuthGetterTypes.CanManageMedicalCenter],
        redirectTo: '/'
    },
    {
        names: [SETUP_GUIDE_ROUTE.name],
        shouldRedirect: (_, { app }) => app.$featureToggle.ensure('ActivationSystem', false),
        redirectTo: '/'
    }
]

export default function permissions({ to, next, router, store }) {
    let nextLocation
    pathPermissions.some((path, index) => {
        const matchPath = to.matched.find(({ name }) => path.names.includes(name))

        if (matchPath && path.shouldRedirect(store, router)) {
            nextLocation = path.redirectTo
            return true
        }

        return false
    })

    if (nextLocation) {
        next(nextLocation)
    } else {
        next()
    }
}
