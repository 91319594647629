import monitor from '@/bootstrap/monitor/monitor'
import { PROFILE_CHANGED } from '@/constants/events'
import SSOCookieService from '@/core/api/utils/ssoCookie.service'
import EventBus from '@/core/ui/libs/eventBus'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { initialiseUnleash } from '@/core/ui/plugins/featureToggle/providers/unleash'
import { getActualToggles } from '@/core/ui/plugins/featureToggle/utils/getActualToggles'
import { finishAndTrackMeasure, startMeasure } from '@/core/ui/plugins/utils/monitoring'
import { ROUTE_SELECT_USER } from '@/features/auth/ui/constants/routes.constants'
import { getApiLogoutPath, getPathMatched, getRedirectPath, goToProviderPath } from '@/router/utils/helpers'
import { SET_CULTURE } from '@/store/mutation-types'
import Storage from '@/store/storage'
import * as STORAGE from '@/store/storage-types'
import { getQueryParamsFromLocationSearch, removeQueryParams } from '@/utils/url'

import AccountService from '../../domain/account.service'
import {
    GOOGLE_ANALYTICS_LINKER_QUERY_PARAM,
    LOGIN_ID_QUERY_PARAM,
    SSO_LOGIN_STATE
} from '../../domain/constants/auth.constants'
import { isAuthenticated } from '../../domain/utils/token.utils'
import { AuthActionTypes, AuthGetterTypes } from '../store/types'

const onError = (error, next) => {
    return next({
        name: 'error',
        params: {
            error
        }
    })
}

// TODO: validate redirection also (https://docplanner.atlassian.net/browse/WSTB-160)
const getMigrationRedirectUrl = to => {
    let url = null

    if (to.name === 'migration' && Object.prototype.hasOwnProperty.call(to.query, 'redirectUrl')) {
        url = to.query.redirectUrl
    } else if (getPathMatched(to) === 'onboarding') {
        url = getRedirectPath(to)
    }

    return url ? { redirectUrl: url } : null
}

const measureIds = {
    measureName: 'MW_auth',
    startMark: 'MW_auth__start',
    endMark: 'MW_auth__end'
}

const authFlowTypesEnum = {
    FinishingAuthentication: 'FinishingAuthentication',
    NoAuthenticated: 'NoAuthenticated',
    HasNoUsers: 'HasNoUsers',
    MustSelectUserRedirected: 'MustSelectUserRedirected',
    MustSelectUser: 'MustSelectUser',
    NotMigrated: 'NotMigrated',
    PartiallyMigrated: 'PartiallyMigrated',
    AuthenticatedAndJustLoggedIn: 'AuthenticatedAndJustLoggedIn',
    AlreadyAuthenticatedAndLoggedIn: 'AlreadyAuthenticatedAndLoggedIn'
}

export default async function auth({ to, next, router, store }) {
    startMeasure(measureIds.startMark)

    const redirectTo = getRedirectPath(to)
    const queryParams = getQueryParamsFromLocationSearch()
    const isUserAuthenticated = isAuthenticated()

    const { state, code } = queryParams

    if (code && state === SSO_LOGIN_STATE) {
        let url
        try {
            url = new URL(redirectTo, window.location.href)
            url.searchParams.delete('code')
            url.searchParams.delete('state')
        } catch (error) {
            monitor.sendException(error)
            url = new URL('/', window.location.href)
        }

        try {
            await store.dispatch(AuthActionTypes.AuthWebToken, code)
            finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.FinishingAuthentication)
            window.location.href = url.href
            return
        } catch (error) {
            return onError(error, next)
        }
    }

    try {
        SSOCookieService.initialize()
    } catch (e) {
        e.message = `Error loading ssoCookie module: ${e.message}`
        monitor.sendException(e)
    }

    if (!isUserAuthenticated) {
        Storage.set(STORAGE.REDIRECT_URL, location.href)
        finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.NoAuthenticated)

        if (ensureToggleIsEnabled('UseSSOTokenOnSaaS', false)) {
            goToProviderPath()
            return
        }

        try {
            await SSOCookieService.getNewToken()
        } catch (error) {
            monitor.sendException(error)

            store.dispatch(AuthActionTypes.AuthLogout)
            Storage.set(STORAGE.REDIRECT_URL, location.href)
            window.location.href = getApiLogoutPath()

            return false
        }
    }

    if (!store.getters[AuthGetterTypes.IsLoggedIn]) {
        const hasAccountUsers = store.getters[AuthGetterTypes.GetAccountUsers].length

        if (!hasAccountUsers) {
            try {
                await store.dispatch(AuthActionTypes.FetchUsersAccountUsers)

                if (store.getters[AuthGetterTypes.GetAccountUsers].length === 0) {
                    await AccountService.create()

                    finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.HasNoUsers)

                    return next({
                        path: 'migration',
                        query: getMigrationRedirectUrl(to)
                    })
                }
            } catch (error) {
                return onError(error, next)
            }
        }

        const marketplaceDoctorId = queryParams[LOGIN_ID_QUERY_PARAM]
        if (marketplaceDoctorId) {
            try {
                const accountUser = store.getters[AuthGetterTypes.GetAccountUserById](marketplaceDoctorId)
                if (!accountUser) {
                    throw new Error(`No user found for ${marketplaceDoctorId}`)
                }
                await store.dispatch(AuthActionTypes.AuthLogin, {
                    userId: accountUser.id
                })
                removeQueryParams([LOGIN_ID_QUERY_PARAM, GOOGLE_ANALYTICS_LINKER_QUERY_PARAM])
            } catch (error) {
                return onError(error, next)
            }
        } else {
            const currentUserSelected = Storage.get(STORAGE.USER_ID)
            if (!currentUserSelected) {
                if (store.getters[AuthGetterTypes.IsMultiUser]) {
                    if (to.name !== ROUTE_SELECT_USER) {
                        finishAndTrackMeasure(
                            router.app.$featureToggle,
                            measureIds,
                            authFlowTypesEnum.MustSelectUserRedirected
                        )

                        return next({
                            name: ROUTE_SELECT_USER,
                            query: {
                                redirectUrl: redirectTo
                            }
                        })
                    }

                    finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.MustSelectUser)

                    return next()
                }
                try {
                    await store.dispatch(AuthActionTypes.AuthLogin, {
                        userId: store.getters[AuthGetterTypes.GetDefaultAccountUser].id
                    })
                } catch (error) {
                    return onError(error, next)
                }
            }
        }

        try {
            await initialiseUnleash({
                toggles: getActualToggles()
            })
        } catch (e) {
            monitor.sendException(e)
        }

        try {
            await store.dispatch(AuthActionTypes.FetchProfile)
            store.dispatch(AuthActionTypes.FetchIsSaasOnly)
            store.dispatch(AuthActionTypes.GetIsPublicPractitioner)

            const authUser = store.getters[AuthGetterTypes.GetAuthUser]

            store.commit(SET_CULTURE, authUser.culture)

            if (!authUser.isMigrated) {
                finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.NotMigrated)

                return next({
                    path: 'migration',
                    query: getMigrationRedirectUrl(to)
                })
            }

            if (authUser.isPartiallyMigrated) {
                finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.PartiallyMigrated)

                return next({
                    path: 'completemigration',
                    query: getMigrationRedirectUrl(to)
                })
            }

            // DOUBT: could it be sent always after store.dispatch(AuthActionTypes.FetchProfile)?
            // Now skipped if migration redirection. Ask plugin-manager developers.
            EventBus.emit(PROFILE_CHANGED, authUser)
        } catch (error) {
            return onError(error, next)
        }

        try {
            await store.dispatch(AuthActionTypes.FetchSaasNavigation)
        } catch (error) {
            return onError(error, next)
        }

        try {
            await store.dispatch('FETCH_FEATURES')

            finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.AuthenticatedAndJustLoggedIn)

            return next(redirectTo)
        } catch (error) {
            return onError(error, next)
        }
    }

    finishAndTrackMeasure(router.app.$featureToggle, measureIds, authFlowTypesEnum.AlreadyAuthenticatedAndLoggedIn)

    next()
}
