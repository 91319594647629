import bookingRules from '@/features/bookingRules/ui/router/bookingRules.router'
import insurance from '@/features/insurance/ui/router/insurance.router'
import uninav from '@/features/navigation/ui/router/uninav.middleware'
import pms from '@/features/pms/ui/router/settings.router'
import privacyPolicy from '@/features/privacyPolicy/ui/router/settings.router'
import rooms from '@/features/rooms/ui/router/rooms.router'
import service from '@/features/service/ui/router/service.router'
import services from '@/features/servicesLegacy/ui/router/settings.router'
import voicemail from '@/features/settings/ui/router/voicemail.router'
import voucher from '@/features/voucher/ui/router/settings.router'
import exportdata from '@/router/settings/export'
import externalCalendar from '@/router/settings/externalCalendar'
import incomeSplit from '@/router/settings/incomeSplit'
import invoicing from '@/router/settings/invoicing'
import preCheckin from '@/router/settings/preCheckin'

import documentationTemplates from './documentation.templates.router'
import fileImportation from './fileImportation.router'
import general from './general.router'
import messages from './messages.router'
import notifications from './notifications.router'
import printLayouts from './printLayouts.router'
import schedules from './schedules.router'
import users from './users.router'

const SettingsIndex = () =>
    import(/* webpackChunkName: "settings-index" */ '@/features/settings/ui/views/SettingsIndex.vue')

export default {
    name: 'settings',
    path: '',
    component: SettingsIndex,
    meta: {
        middleware: uninav,
        uninavName: 'settings',
        title: 'settings'
    },
    children: [
        general,
        ...bookingRules,
        ...service,
        ...insurance,
        ...services,
        ...schedules,
        ...notifications,
        ...exportdata,
        ...incomeSplit,
        ...preCheckin,
        ...externalCalendar,
        ...invoicing,
        ...voucher,
        ...pms,
        ...messages,
        ...fileImportation,
        ...privacyPolicy,
        ...printLayouts,
        ...rooms,
        documentationTemplates,
        users,
        ...voicemail
    ]
}
